import { Body1, Body2, H5 } from '@cointracker/styleguide';
import { Grid } from '@cointracker/styleguide/src/Grid';
import OliveLeafLeft from '@cointracker/styleguide/src/icons/olive-branch-left.svg?react';
import OliveLeafRight from '@cointracker/styleguide/src/icons/olive-branch-right.svg?react';
import FullStar from '@cointracker/styleguide/src/icons/star-full.svg?react';
import { type ReactNode } from 'react';
import {
  creditBlock,
  items,
  star,
  starBackground,
  starFill,
} from './CreditBlock.css';

const STAR_SIZE = {
  width: 28,
  height: 28,
};

export function CreditBlock() {
  return (
    <Grid className={creditBlock}>
      <div className={items}>
        <CreditBlockItem title="App Store" proof="300k downloads">
          <div className="gap-tiny3 flex flex-row items-center justify-center">
            <Star ratio={1} />
            <Star ratio={1} />
            <Star ratio={1} />
            <Star ratio={1} />
            <Star ratio={0.5} />
          </div>
        </CreditBlockItem>
        <CreditBlockItem title="Milkroad" proof="2024 crypto tax product">
          <H5>#1</H5>
        </CreditBlockItem>
        <CreditBlockItem title="Trustpilot" proof="Excellent rating">
          <div className="gap-tiny3 flex flex-row items-center justify-center">
            <Star ratio={1} />
            <Star ratio={1} />
            <Star ratio={1} />
            <Star ratio={1} />
            <Star ratio={0.7} />
          </div>
        </CreditBlockItem>
      </div>
    </Grid>
  );
}

const CreditBlockItem = ({
  title,
  proof,
  children,
}: {
  title: string;
  proof: string;
  children: ReactNode;
}) => {
  return (
    <div className="flex flex-row items-center justify-center gap-8">
      <OliveLeafLeft className="h-[105px] w-[54px] text-blue-90 md:h-120 md:w-[61px]" />
      <div className="flex flex-col items-center justify-center gap-8">
        <Body1 weight="bold">{title}</Body1>
        {children}
        <Body2>{proof}</Body2>
      </div>
      <OliveLeafRight className="h-[105px] w-[54px] text-blue-90 md:h-120 md:w-[61px]" />
    </div>
  );
};

interface StarProps {
  ratio: number;
}

const Star = (props: StarProps) => {
  const { ratio } = props;

  return (
    <div className={star}>
      <div className={starBackground}>
        <FullStar {...STAR_SIZE} />
      </div>
      <div className={starFill} style={{ width: `${ratio * 100}%` }}>
        <FullStar {...STAR_SIZE} />
      </div>
    </div>
  );
};
