import { isClient } from '@cointracker/ui';
import { useEffect, useState } from 'react';
import Statsig from 'statsig-js';
import { initializeClientStatsig } from '../../common/statsig';

interface UseGateReturn {
  loading: boolean;
  value: boolean;
  error?: string;
}

export const useGate = (gateName: string): UseGateReturn => {
  const [isFeatureEnabled, setIsFeatureEnabled] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | undefined>();

  useEffect(() => {
    if (!gateName) {
      setLoading(false);
      setError('gateName is required');
      return;
    }
    const initStatsig = async () => {
      let statsig: typeof Statsig;
      try {
        statsig = await initializeClientStatsig();
      } catch {
        setLoading(false);
        setError('Failed to initialize statsig');
        return;
      }
      setIsFeatureEnabled(statsig.checkGate(gateName));
      setLoading(false);
    };

    initStatsig();
  }, [gateName]);

  if (!isClient()) {
    return {
      loading: false,
      value: false,
      error: 'useGate is only available on the client',
    };
  }

  return {
    loading,
    value: isFeatureEnabled,
    error,
  };
};
