import { Body1, Body2 } from '@cointracker/styleguide';
import { forwardRef, type Ref } from 'react';
import { column, container, header, row, text } from './PartnerTable.css';
import Check from './icons/Check.svg?react';
import Cross from './icons/Cross.svg?react';
import Minus from './icons/Minus.svg?react';

const TableHeader = forwardRef(
  (props: React.HTMLAttributes<HTMLElement>, ref: Ref<HTMLElement>) => {
    const { ...rest } = props;

    return (
      <header className={header} ref={ref} {...rest}>
        <div className={row}>
          <div className={text}>
            <Body2>Solana Defi Accuracy</Body2>
          </div>
          <div className={column}>
            <Body2>Cointracker</Body2>
          </div>
          <div className={column}>
            <Body2>Base</Body2>
          </div>
          <div className={column}>
            <Body2>Ultra</Body2>
          </div>
        </div>
      </header>
    );
  },
);

TableHeader.displayName = 'TableHeader';

type TableRowProps = {
  children: React.ReactNode;
  className?: string;
  cointracker?: 'check' | 'minus' | 'cross';
  base?: 'check' | 'minus' | 'cross';
  ultra?: 'check' | 'minus' | 'cross';
};

const TableRow = forwardRef(
  (props: TableRowProps, ref: Ref<HTMLDivElement>) => {
    const { children, cointracker, base, ultra, ...rest } = props;

    return (
      <div className={row} ref={ref} {...rest}>
        <div className={text}>
          <Body1>{children}</Body1>
        </div>
        <div className={column}>
          {cointracker == 'check' && <Check height="25px" />}
          {cointracker == 'minus' && <Minus height="25px" />}
          {cointracker == 'cross' && <Cross height="25px" />}
        </div>
        <div className={column}>
          {base == 'check' && <Check height="25px" />}
          {base == 'minus' && <Minus height="25px" />}
          {base == 'cross' && <Cross height="25px" />}
        </div>
        <div className={column}>
          {ultra == 'check' && <Check height="25px" />}
          {ultra == 'minus' && <Minus height="25px" />}
          {ultra == 'cross' && <Cross height="25px" />}
        </div>
      </div>
    );
  },
);

TableRow.displayName = 'TableRow';

export const SolanaTable = forwardRef(
  (props: React.HTMLAttributes<HTMLDivElement>, ref: Ref<HTMLDivElement>) => {
    const { ...rest } = props;
    return (
      <div className={container} ref={ref} {...rest}>
        <TableHeader />
        <TableRow cointracker="check" base="check" ultra="check">
          Dex Swaps
        </TableRow>
        <TableRow cointracker="check" base="check" ultra="minus">
          NFTs
        </TableRow>

        <TableRow cointracker="check" base="minus" ultra="cross">
          Native staking
        </TableRow>
        <TableRow cointracker="check" base="cross" ultra="cross">
          Staking pools
        </TableRow>

        <TableRow cointracker="check" base="cross" ultra="cross">
          SPL staking
        </TableRow>
        <TableRow cointracker="check" base="cross" ultra="cross">
          Lending
        </TableRow>
        <TableRow cointracker="check" base="cross" ultra="cross">
          Liquidity
        </TableRow>

        <TableRow cointracker="check" base="cross" ultra="cross">
          Rewards
        </TableRow>

        <TableRow cointracker="check" base="cross" ultra="cross">
          Airdrops
        </TableRow>
        <TableRow cointracker="check" base="check" ultra="cross">
          Rent Fees
        </TableRow>
      </div>
    );
  },
);

SolanaTable.displayName = 'SolanaTable';
