import { parseCookie } from '@cointracker/ui';
import statsigClient, {
  type StatsigEnvironment as StatsigEnvironmentClient,
  type StatsigOptions as StatsigOptionsClient,
} from 'statsig-js';

const statsig = statsigClient;

const clientSecretKey = import.meta.env.PUBLIC_STATSIG_CLIENT_SDK_KEY;
const deploymentMode = import.meta.env.MODE;

const session_keys = {
  ANONYMOUS_ID: 'anonymous_id',
  PUBLIC_ID: 'user_public_id',
};

export const getUserDataFromCookies = () => {
  const cookie = parseCookie(document.cookie);
  return {
    userID: cookie?.[session_keys.PUBLIC_ID],
    anonymousID: cookie?.[session_keys.ANONYMOUS_ID],
  };
};

const getInitializeOptions = () => {
  let environment: StatsigEnvironmentClient;

  if (deploymentMode === 'production') {
    environment = { tier: 'production' };
  } else if (deploymentMode === 'development') {
    environment = { tier: 'development' };
  } else {
    environment = { tier: 'staging' };
  }

  const options: StatsigOptionsClient = {
    environment: environment,
  };

  return options;
};

export const initializeClientStatsig = async () => {
  const userDataFromCookies = getUserDataFromCookies();

  if (statsig.initializeCalled() || window.statsig) {
    return window.statsig;
  }
  window.statsig = statsig;
  await statsig.initialize(
    clientSecretKey,
    {
      userID: userDataFromCookies.userID,
      customIDs: { anonymousID: userDataFromCookies.anonymousID! },
    },
    getInitializeOptions(),
  );
  return window.statsig;
};

export default statsig;
